<template>

<v-container class="pa-0 mb-4">


  <v-data-table
    :headers="headers"
    :items="arrayGestiones"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="opcion1">
        <v-toolbar-title> Historico Gestiones</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

          <v-flex  xs4 sm4 md3 lg3>
          <v-text-field  justify-center  v-model="search"  append-icon="search" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>

          <v-btn color="indigo" dark class=" ml-9 mt-1" @click="dialogInicio=true"><v-icon>today</v-icon></v-btn>



        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="600px">
         <v-card>
           <v-toolbar color="red">
            <v-toolbar-title class="white--text">{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="dialog=false">close</v-icon>
          </v-toolbar>



            <v-card-text>
              <v-container>
                <v-row>

                                                <v-col cols="6">
                                                    <v-menu
                                                        ref="menu2"
                                                        v-model="menu2"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="290px"
                                                        min-width="auto"
                                                        >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                            v-model="editedItem.fecha"
                                                            label="Fecha gestion"
                                                            prepend-icon="date_range"
                                                        
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="editedItem.fecha"
                                                            no-title
                                                            @input="menu2 = false"
                                                        ></v-date-picker>
                                                        </v-menu>
                                                    </v-col>


                                                

                                                     <v-col cols="12" sm="4" md="4" >
                                                      <v-select
                                                        v-model="editedItem.tipo_gestion"
                                                        :items="arrayViaContacto"
                                                        :menu-props="{ top: true, offsetY: true }"
                                                        prepend-icon="label"
                                                        label="Via contacto"
                                                      ></v-select>
                                                  </v-col>


                                                    <v-col cols="12" sm="12" md="12">
                                                      <v-textarea
                                                        name="Descripcion"
                                                        label="descripcion"
                                                        v-model="editedItem.descripcion"
                                                        rows="1"
                                                        prepend-icon="comment"
                                                        >
                                                      </v-textarea>
                                                  </v-col>

                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="success" @click="save">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">deseas borrar registro?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

     <v-dialog v-model="dialogInicio" persistent max-width="280">
        <v-card>
          <v-toolbar flat color="red" class="text-center">
          <v-toolbar-title class="white--text  text-center"><strong>Historico por fechas</strong></v-toolbar-title>
        </v-toolbar>

          <v-card-text>



            <v-flex>
        
            

                      <v-menu
                        ref="menuFecha1"
                        v-model="menuFecha1"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                    
                        offset-y
                        min-width="290px"
                      >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fecha_inicio"
                                label="Fecha inicio"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_inicio" locale="es"
                            no-title  @input="menuFecha1 = false" scrollable>
                            </v-date-picker>
                      </v-menu>
                </v-flex>

            <v-flex>
                  <v-menu
                    ref="menuFecha2"
                    v-model="menuFecha2"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                
                    offset-y
                    min-width="290px"
                  >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fecha_fin"
                            label="Fecha fin"
                            prepend-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="fecha_fin" locale="es"
                        no-title  @input="menuFecha2 = false" scrollable>
                        </v-date-picker>
                  </v-menu>

                </v-flex>

            
                  <v-flex justify-center d-flex>
                    
                        <v-btn color="success" dark @click="Procesar()" style="margin-right:10px;">Generar</v-btn>
                      <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

                </v-flex>
    
          </v-card-text>
        
        </v-card>
        </v-dialog>


      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
       edit
      </v-icon>
      <v-icon  small @click="deleteItem(item)">
        delete
      </v-icon>
    </template>

     <template v-slot:[`item.fecha`]="{ item }">
        <span>{{ getFecha(item.fecha)}}</span>
    </template>

    <template v-slot:no-data>
      <v-btn  color="primary" @click="listar()">
        Reset
      </v-btn>
    </template>

  

  </v-data-table>


</v-container>
</template>


<script>


import axios from 'axios';
import { mapMutations,mapState } from "vuex";

  export default {
    data: () => ({
      search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        
         { text: 'Cliente', value: 'id_gestion_cliente.nombre_cliente' },
         { text: 'Tipo gestion', value: 'tipo_gestion' },
         { text: 'Fecha', value: 'fecha' },
         { text: 'Descripcion', value: 'descripcion' },
         { text: 'Actualizado', value: 'actualizado' },
         { text: 'Ejecutivo', value: 'id_gestion_ejecutivo.nombre_ejecutivo' },
         { text: 'Actions', value: 'actions', sortable: false },
      ],
      arrayGestiones: [],
      editedIndex: -1,
      editedItem: {
        _id:'',
        tipo_gestion: '',
        departamento: 'Contabilidad',
        id_gestion_cliente: '',
        fecha: new Date().toISOString().substr(0, 10),
        descripcion: '',
        id_gestion_ejecutivo:'',
        actualizado:'',
        activo:1
      },
      defaultItem: {
          _id:'',
        tipo_gestion: '',
        departamento: 'Contabilidad',
        id_gestion_cliente: '',
        fecha: new Date().toISOString().substr(0, 10),
        descripcion: '',
        id_gestion_ejecutivo:'',
        actualizado:'',
        activo:1
      },

       arrayViaContacto: ['Llamada', 'Whatsapp', 'Correo', 'Telegram','Visita'],


        menu: false,
        dialogInicio:true,
        menuFecha1:false,
        fecha_inicio:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_fin:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),

        menu2:false,

    }),

    computed: {


          ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo' : 'Edicion'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.dialogInicio=true;

    },

    methods: {


      ...mapMutations(['mostrarLoading','ocultarLoading']),


      Procesar(){


          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});

          let me=this;

            axios.post('gestiones-departamento',
                               { 
                                'desde':new Date(this.fecha_inicio).toISOString().substr(0, 10),
                                'hasta':new Date(this.fecha_fin).toISOString().substr(0, 10),
                                'departamento':'Contabilidad' 
                              },
             config
          ).then(function(response){
                console.log(response);
                 let respuesta=response.data;
                 me.arrayGestiones=respuesta;

                   me.ocultarLoading();

                   me.dialogInicio=false;

         }).catch(function(error){
            console.log(error);
          });


      },
      
      editItem (item) {
        this.editedIndex = this.arrayGestiones.indexOf(item)
        this.editedItem = Object.assign({}, item);
        this.editedItem.fecha=new Date(this.editedItem.fecha).toISOString().substr(0, 10);
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.arrayGestiones.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.arrayGestiones.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {

            let config={headers:{token:this.token}};
           this.mostrarLoading({titulo:'Accediendo a datos'});

           let me=this;

           console.log({ 
                  'fecha':new Date(this.editedItem.fecha),
                  'descripcion':this.editedItem.descripcion,
                  'tipo_gestion':this.editedItem.tipo_gestion,
                  'actualizado':this.usuario.nombre_ejecutivo+' a las:'+ me.ConvertirFecha(new Date())
                  });

      
          axios.put(`gestiones/${this.editedItem._id}`,
                { 
                  'fecha':new Date(this.editedItem.fecha+" 00:00:00"),
                  'descripcion':this.editedItem.descripcion,
                  'tipo_gestion':this.editedItem.tipo_gestion,
                  'actualizado':this.usuario.nombre_ejecutivo+' a las:'+ me.ConvertirFecha(new Date())
                  },
                  config

                  ).then(response=>{

                   console.log(response);
                   me.ocultarLoading();
                   me.dialog=false;
                
                   me.Procesar();
        

                }).catch(e=>{
                      console.log(e)
                      this.mensaje=e
                });
         

             this.close()

         } 
        
      },

    ConvertirFecha(fecha_parametro){

        let texto=new Date(fecha_parametro).toLocaleDateString()+" "+new Date(fecha_parametro).toLocaleTimeString();

        return(texto);

    },

    getFecha(evaluar) {
        let date = new Date(evaluar),
        year = date.getFullYear(),
        month = (date.getMonth() + 1).toString(),
        formatedMonth = (month.length === 1) ? ("0" + month) : month,
        day = date.getDate().toString(),
        formatedDay = (day.length === 1) ? ("0" + day) : day;
        return formatedDay + "-" + formatedMonth + "-" + year;
    },



    },
  }
</script>